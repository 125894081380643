@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  overflow: hidden; /* Prevent scrollbars from being displayed */
  overflow-y: scroll; /* Allows vertical scrolling without displaying a scrollbar */
}

body::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Webkit browsers */
}

/* Headings */
.markdown-question-text * {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.markdown-question-text h1,
.markdown-question-text h2,
.markdown-question-text h3,
.markdown-question-text h4,
.markdown-question-text h5,
.markdown-question-text h6 {
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.5em;
  line-height: 1.25;
}

.markdown-question-text h1 {
  font-size: 2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.3em;
}

.markdown-question-text h2 {
  font-size: 1.75em;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.3em;
}

.markdown-question-text h3 {
  font-size: 1.5em;
}

.markdown-question-text h4 {
  font-size: 1.25em;
}

.markdown-question-text h5 {
  font-size: 1em;
}

.markdown-question-text h6 {
  font-size: 0.875em;
  color: #555;
}

/* Paragraphs and Text */
.markdown-question-text p {
  margin: 0 0;
}

.markdown-question-text strong {
  font-weight: bold;
}

.markdown-question-text em {
  font-style: italic;
}

.markdown-question-text del {
  text-decoration: line-through;
}

/* Lists */
.markdown-question-text ul {
  list-style-type: disc;
  margin-left: 1.5em;
  padding-left: 1em;
}

.markdown-question-text ol {
  list-style-type: decimal;
  margin-left: 1.5em;
  padding-left: 1em;
}

.markdown-question-text li {
  margin-bottom: 0.5em;
}

/* Blockquotes */
.markdown-question-text blockquote {
  border-left: 4px solid #ddd;
  padding-left: 1em;
  color: #555;
  margin: 1em 0;
  font-style: italic;
}

/* Code Blocks and Inline Code */
.markdown-question-text pre {
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
}

.markdown-question-text code {
  background-color: #f4f4f4;
  border-radius: 3px;
  padding: 2px 4px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
}

/* Tables */
.markdown-question-text table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown-question-text th,
.markdown-question-text td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-question-text th {
  background-color: #f4f4f4;
  font-weight: bold;
}

/* Links */
.markdown-question-text a {
  color: #007bff;
  text-decoration: none;
}

.markdown-question-text a:hover {
  text-decoration: underline;
}

/* Horizontal Rule */
.markdown-question-text hr {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 1.5em 0;
}

@layer components {
  .question-content-container {
    @apply flex flex-col gap-8;
  }

  .question-section-content {
    @apply flex flex-1 flex-col items-center justify-center gap-4;
  }

  .question-leafs {
    @apply flex flex-col gap-8;
  }

  .question-indexed-section {
    @apply flex flex-1 gap-6;
  }

  .question-indexed-section-content {
    @apply flex flex-col gap-4;
  }

  .question-index-label,
  .question-subindex-label {
    @apply text-sm;
    @apply font-semibold;
  }

  .question-subindexed-section {
    @apply flex flex-col gap-5;
  }
  .is-active {
    @apply rounded-md bg-gray-200 px-2 py-1 dark:bg-gray-700 dark:text-white;
  }
  .text-muted {
    @apply text-gray-500 dark:text-gray-400;
  }
  /* Base styling for the Tiptap editor */
  .tiptap {
    @apply first:mt-0;
  }

  /* Table styles */
  .tiptap table {
    @apply m-0 w-full table-fixed border-collapse overflow-hidden text-sm;
  }

  .tiptap td,
  .tiptap th {
    @apply relative box-border min-w-[1em] border border-gray-300 p-2 align-top;
  }

  .tiptap td > *,
  .tiptap th > * {
    @apply mb-0;
  }

  .tiptap th {
    @apply bg-gray-100 text-left font-bold;
  }

  /* Selected cell highlight */
  .tiptap .selectedCell::after {
    @apply pointer-events-none absolute inset-0 z-10 bg-gray-200 content-[''];
  }

  /* Column resize handle */
  .tiptap .column-resize-handle {
    @apply pointer-events-none absolute bottom-[-2px] right-[-2px] top-0 w-1 bg-blue-500;
  }

  /* Wrapper for table scrolling */
  .tiptap .tableWrapper {
    @apply my-6 w-full;
  }

  .tiptap img {
    @apply mx-auto;
  }

  /* Resize cursor for table columns */
  .tiptap.resize-cursor {
    @apply cursor-col-resize cursor-ew-resize;
  }

  .menubar-container {
    @apply flex max-w-2xl flex-wrap items-center gap-2 rounded-t-md border border-gray-300 bg-white p-3 shadow-md;
  }

  .menubar-button {
    @apply rounded-md p-2 text-gray-700 transition-all hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700;
  }

  .menubar-item {
    @apply flex cursor-pointer items-center gap-2 rounded-md px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700;
  }

  .editor-container {
    @apply max-h-96 min-h-72 max-w-full overflow-y-auto rounded-b-lg border border-gray-300 bg-white p-4 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50 dark:border-gray-700 dark:bg-gray-900 dark:text-white dark:focus:border-blue-400 dark:focus:ring-blue-500;
  }

  .menubar-separator {
    @apply mx-2 h-6 w-px bg-gray-300;
  }

  .editor-content {
    @apply prose prose-sm max-w-full overflow-x-auto p-3 text-gray-700 xl:prose-base focus:outline-none dark:text-white;
  }

  .menubar-typography {
    @apply prose prose-sm w-52;
  }

  .menubar-heading {
    @apply m-0 text-gray-700 dark:text-white;
  }
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
